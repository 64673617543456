<template>
  <div class="row">
    <div class="col-12 col-lg-4">
      <div class="card">
        <div class="card-header">البيانات الشخصية</div>
        <div class="card-body">
          <div class="media">
            <a class="d-flex" href="#">
              <img src="../../assets/images/arab-avatar.png" alt="" />
            </a>
            <div class="media-body">
              <br /><br />
              <h4>{{ teacher.name }}</h4>
              # {{ teacher.number }}
            </div>
          </div>
          <br />
          <table class="table">
            <tbody v-if="reports.late_minutes || reports.late_minutes == 0">
              <tr>
                <td>عدد دقائق التأخر الكلية</td>
                <td>{{ reports.late_minutes.toFixed(1) }} دقيقة</td>
              </tr>
              <tr>
                <td>عدد ساعات التأخر الكلية</td>
                <td>{{ (reports.late_minutes / 60).toFixed(1) }} ساعة</td>
              </tr>
            </tbody>
          </table>
          <div class="col-12 text-center">
            <button class="btn btn-sm btn-outline-primary" style="border-radius: 0px" @click="printStats()">
              <i class="fa fa-print"></i>
              طباعة الاحصائيات
            </button>
          </div>
        </div>
      </div>
      <p>مساءلات المعلم: <button class="btn btn-sm btn-primary" v-b-modal.modal-2>+ اضافة مساءلة</button></p>
      <div v-if="asks">
        <div v-if="asks.length">
          <div class="row">
          <template v-for="ask in asks">
              <div class="col-12"
                v-bind:key="ask._id"
                v-if="ask.type.includes('other')"
              >
                <div class="card card-body">
                  <h4
                    @click="
                      go(
                        `/_teachers/reports/ask?day=day&date=${ask.date}&number=${teacher.number}&type=${ask.type}`
                      )
                    "
                  >
                    <span v-if="ask.type == 'other-1'">
                      عدم تواجد داخل الفصل
                    </span>
                    <span v-if="ask.type == 'other-2'">
                      خروجك من الحصة قبل انتهاء الوقت
                    </span>
                    <span v-if="ask.type == 'other-3'">
                      عدم تحضير الدروس
                    </span>
                    <span v-if="ask.type == 'other-4'">
                      عدم المناوبة في الفسحة
                    </span>
                    <span v-if="ask.type == 'other-5'">
                      عدم المناوبة في الممرات
                    </span>
                    <span v-if="ask.type == 'other-6'">
                      عدم المناوبة في نهاية الدوام
                    </span>
                    <span v-if="ask.type == 'other-7'">
                      عدم الحضور لحصص الانتظار
                    </span>
                  </h4>
                  <h5>
                    بتاريخ
                    <span dir="ltr">{{ ask.date }}</span> - <a href="javacsript:;" @click="deleteAsk(ask._id)" class="text-danger"><i class="fa fa-trash"> حذف</i></a>
                  </h5>
                </div>
              </div>
            </template>
          </div>
          </div>
          </div>
    </div>
    <div class="col-12 col-lg-8">
      <div class="card">
        <div class="card-header">
          📃 تقارير الحضور وغياب وتأخر (آخر 30 يوم)
          <button
            class="btn btn-primary"
            @click="sstatus('all')"
            v-b-modal.modal-1
          >
            عرض التفاصيل
          </button>
          <div>
            من
            <input
              type="date"
              ref="from"
              v-model="from"
              @change="getReports()"
            />
            إلى
            <input type="date" ref="to" v-model="to" @change="getReports()" />
          </div>
        </div>
      </div>
      <div class="row" v-if="reports.reports">
        <div class="col-12 col-lg-4" @click="sstatus(1)" v-b-modal.modal-1>
          <statistic-card-horizontal
            icon="CheckSquareIcon"
            :statistic="reports.reports[1].length"
            statistic-title="عدد أيام الحضور"
          />
        </div>
        <div class="col-12 col-lg-4" @click="sstatus(2)">
          <statistic-card-horizontal
            icon="ClockIcon"
            v-b-modal.modal-1
            :statistic="reports.reports[2].length"
            :statistic-title="`عدد أيام التأخر (${(
              reports.late_minutes / 60
            ).toFixed(1)} ساعة)`"
          />
          <button class="btn btn-outline-danger btn-block btn-sm" style="margin-top: -10px" 
          @click="$router.push(`/_teachers/reports/ask?day=day&date=${reports.reports[2].map(function(x){return x.date}).join(',')}&hours=${(reports.late_minutes / 60).toFixed(1)}&number=${reports.number}&type=latedis`)">
            حسم لساعات التأخر
          </button>
        </div>
        <div class="col-12 col-lg-4" @click="sstatus(3)">
          <statistic-card-horizontal
            icon="XCircleIcon"
            v-b-modal.modal-1
            :statistic="reports.reports[3].length"
            statistic-title="عدد أيام الغياب"
          />
          <button class="btn btn-outline-danger btn-block btn-sm" style="margin-top: -10px" 
          @click="$router.push(`/_teachers/reports/ask?day=day&date=${reports.reports[3].map(function(x){return x.date}).join(',')}&number=${reports.number}&type=dis`)">
            حسم لأيام الغياب
          </button>
        </div>
      </div>
      <div v-if="asks">
        <div v-if="asks.length">
          <div class="row">
            <div class="col-12">
              <div class="alert">مساءلات الغياب والتأخر:</div>
            </div>
            <template 
              v-for="ask in asks">
            <div
              class="col-12"
              v-bind:key="ask._id"
              v-if="!ask.type.includes('other') && !ask.type.includes('dis')"
            >
              <div class="card card-body">
                <h4
                  v-if="ask.type == 'ghiab'"
                  @click="
                    go(
                      `/_teachers/reports/ask?day=day&date=${ask.date}&number=${teacher.number}&type=${ask.type}`
                    )
                  "
                >
                  مساءلة غياب
                </h4>
                <h4
                  v-if="ask.type == 'late'"
                  @click="
                    go(
                      `/_teachers/reports/ask?day=day&date=${ask.date}&number=${teacher.number}&type=${ask.type}`
                    )
                  "
                >
                  مساءلة تأخر
                </h4>
                <h4
                  v-if="ask.type == 'lateBetween'"
                  @click="
                    go(
                      `/_teachers/reports/ask?day=day&date=${ask.date}&number=${teacher.number}&type=${ask.type}`
                    )
                  "
                >
                  مساءلة تأخر لفترة
                </h4>
                <h5 v-if="ask.type != 'lateBetween'">
                  بتاريخ
                  <span dir="ltr">{{ ask.date }}</span> - <a href="javacsript:;" @click="deleteAsk(ask._id)" class="text-danger"><i class="fa fa-trash"> حذف</i></a>
                </h5>
                <h5 v-if="ask.type == 'lateBetween'">
                  من
                  <span dir="ltr">{{ ask.from }}</span> إلى
                  <span dir="ltr">{{ ask.to }}</span> - <a href="javacsript:;" @click="deleteAsk(ask._id)" class="text-danger"><i class="fa fa-trash"> حذف</i></a>
                </h5>
              </div>
            </div>
            </template>
          </div>
        </div>
      </div>
      <div v-if="asks">
        <div v-if="asks.length">
          <div class="row">
            <div class="col-12">
              <div class="alert">الحسومات</div>
            </div>
            <template 
              v-for="ask in asks">
            <div
              class="col-12"
              v-bind:key="ask._id"
              v-if="ask.type.includes('dis')"
            >
              <div class="card card-body">
                <h4
                  v-if="ask.type == 'dis'"
                  @click="
                    go(
                      `/_teachers/reports/ask?day=day&date=${ask.date}&number=${teacher.number}&type=${ask.type}`
                    )
                  "
                >
                  حسم غياب {{ ask.date.split(",").length }} يوم
                  <br>
                  <small>من
                  <span dir="ltr">{{ ask.date.split(",")[0] }}</span> إلى
                  <span dir="ltr">{{ ask.date.split(",")[ask.date.split(",").length-1] }}</span> - <a href="javacsript:;" @click="deleteAsk(ask._id)" class="text-danger"><i class="fa fa-trash"> حذف</i></a></small>
                </h4>
                <h4
                  v-if="ask.type == 'latedis'"
                  @click="
                    go(
                      `/_teachers/reports/ask?day=day&date=${ask.date}&number=${teacher.number}&hours=${ask.info.hours}&type=${ask.type}`
                    )
                  "
                >
                  حسم تأخر <span v-if="ask.info">
                    {{ ask.info.hours }} ساعة
                  </span>
                  <br>
                  <small>من
                  <span dir="ltr">{{ ask.date.split(",")[0] }}</span> إلى
                  <span dir="ltr">{{ ask.date.split(",")[ask.date.split(",").length-1] }}</span> - <a href="javacsript:;" @click="deleteAsk(ask._id)" class="text-danger"><i class="fa fa-trash"> حذف</i></a></small>
                </h4>
                
            </div>
            </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="تفاصيل التقرير"
      ok-only
      ok-title="حسناً، شكراً لك"
    >
      <b-card-text>
        <div class="col-12 g text-center">
          <button class="btn btn-info" @click="print()">طباعة الجدول</button>
        </div>
        <div class="col-12 table-responsive" ref="gtable">
          <table class="table table-bordered table-hover">
            <thead>
              <th>التاريخ</th>
              <th>الحالة</th>
            </thead>
            <tbody>
              <tr v-for="a in reports.arr" v-bind:key="a._id">
                <template v-if="a.status == status || status == 'all'">
                  <td>
                    <strong>{{ a.date }}</strong>
                  </td>
                  <td>
                    <span class="badge badge-secondary" v-if="a.status == 0"
                      >خارج وقت الدوام</span
                    >
                    <span class="badge badge-success" v-if="a.status == 1"
                      >حاضر</span
                    >
                    <span class="badge badge-warning" v-if="a.status == 2"
                      >متأخر</span
                    >
                    <div v-if="a.status == 2 && a.late_time > 0">
                      متأخر: {{ (a.late_time).toFixed(0) }} دقيقة ({{
                        (a.late_time / 60).toFixed(1)
                      }}
                      ساعة)
                    </div>
                    <span class="badge badge-danger" v-if="a.status == 3"
                      >غائب</span
                    >
                    <span class="badge badge-primary" v-if="a.status == 5"
                      >غائب بعذر</span
                    >
                    <span class="badge badge-info" v-if="a.status == 6"
                      >اجازة</span
                    >
                    <span v-if="a.reason">
                        <br>
                        {{ a.reason }}
                    </span>
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card-text>
    </b-modal>
    <!-- basic modal -->
    <b-modal
      id="modal-2"
      ok-only
    >
      <div class="row">
        <div class="col-12 g">
          <button class="btn btn-primary btn-block" @click="addAsk(1)">
             عدم تواجد داخل الفصل
          </button>
        </div>
        <div class="col-12 g">
          <button class="btn btn-primary btn-block" @click="addAsk(2)">
            خروجك من الحصة قبل انتهاء الوقت
          </button>
        </div>
        <div class="col-12 g">
          <button class="btn btn-primary btn-block" @click="addAsk(3)">
             عدم تحضير الدروس
          </button>
        </div>
        <div class="col-12 g">
          <button class="btn btn-primary btn-block" @click="addAsk(4)">
            عدم المناوبة في الفسحة
          </button>
        </div>
        <div class="col-12 g">
          <button class="btn btn-primary btn-block" @click="addAsk(5)">
            عدم المناوبة في الممرات
          </button>
        </div>
        <div class="col-12 g">
          <button class="btn btn-primary btn-block" @click="addAsk(6)">
            عدم المناوبة في نهاية الدوام
          </button>
        </div>
        <div class="col-12 g">
          <button class="btn btn-primary btn-block" @click="addAsk(7)">
            عدم الحضور لحصص الانتظار
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BCardText,
  BLink,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const axios = require("axios");
var moment = require("moment-hijri");
export default {
  components: {
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    StatisticCardHorizontal,
    BLink,
  },
  data() {
    return {
      teacher: {},
      id: null,
      status: "all",
      reports: [],
      info: {},
      asks: [],
      from: new Date(new Date().setDate(new Date().getDate() - 30))
        .toISOString()
        .split("T")[0],
      user: JSON.parse(localStorage.getItem('user')),
      to: new Date().toISOString().split("T")[0],
    };
  },
  created() {
    this.id = window.location.pathname.split("/overview/")[1];
    var _g = this;
    axios
      .post(localStorage.getItem("api") + "/user/teachers/teacher", {
        jwt: localStorage.getItem("jwt"),
        number: this.id,
        asks: true
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.teacher = r.data.response;
          _g.asks = r.data.response.asks
          setTimeout(() => {
            _g.getReports();
          }, 1);
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    deleteAsk(id){
      var g = this;
      if(confirm("متأكد من حذف المسائلة؟")){
        axios
          .post(api + "/user/teachers/reports/delete-ask", {
            jwt: this.user.jwt,
            id: id
          }).then(function(){
            alert('تم بنجاح');
            location.reload()
          })
      }
    },
    printStats(){
      var g = this;
var printWindow = window.open("", "");
      printWindow.document.write(
        "<html lang='ar'><head dir='rtl'><title>tahdir.net</title>"
      );

      //Print the Table CSS.
      printWindow.document.write('<style type = "text/css">');
      printWindow.document.write(`
         body
          {
              font-family: Arial;
              font-size: 10pt;
              direction: rtl;
          }
          table
          {
              border: 1px solid #000;
              border-collapse: collapse;
              width: 100%
          }
          table th
          {
              font-weight: bold;
          }
          table th, table td
          {
              padding: 5px;
              border: 1px solid #ccc;
          }
          .no {
            display: none;
          }
        `);
      printWindow.document.write("</style>");
      printWindow.document.write("</head>");
      printWindow.document.write(`
      <h2>احصائيات المعلم <strong>${g.teacher.name}</strong></h2>
      <p>الفترة من ${g.from} الى ${g.to}</p>
      <table>
        <tbody>
          <tr>
            <td>
            عدد أيام الحضور
            </td>
            <td>
              ${g.reports.reports[1].length}
            </td>
          </tr>
          <tr>
            <td>
             عدد أيام التأخر
            </td>
            <td>
              ${g.reports.reports[2].length}
            </td>
          </tr>
          <tr>
            <td>
            عدد أيام الغياب
            </td>
            <td>
            ${g.reports.reports[3].length}
            </td>
          </tr>
          <tr>
              <td>عدد دقائق التأخر الكلية</td>
              <td>${g.reports.late_minutes.toFixed(1)} دقيقة</td>
          </tr>
          <tr>
              <td>عدد ساعات التأخر الكلية</td>
              <td>${(g.reports.late_minutes / 60).toFixed(1)} ساعة</td>
          </tr>
        </tbody>
      </table>
      `);
      printWindow.document.write(
        "</body>"
      );

      printWindow.document.write("</html>");
      printWindow.document.close();
      printWindow.document.designMode = "on"
      setTimeout(() => {
        printWindow.print();
      }, 1000);
    },
    print() {
      var printWindow = window.open("", "");
      printWindow.document.write(
        "<html lang='ar'><head dir='rtl'><title>tahdir.net</title>"
      );

      //Print the Table CSS.
      printWindow.document.write('<style type = "text/css">');
      printWindow.document.write(`
         body
          {
              font-family: Arial;
              font-size: 10pt;
              direction: rtl;
          }
          table
          {
              border: 1px solid #ccc;
              border-collapse: collapse;
              width: 100%
          }
          table th
          {
              background-color: #F7F7F7;
              color: #333;
              font-weight: bold;
          }
          table th, table td
          {
              padding: 5px;
              border: 1px solid #ccc;
          }
          .no {
            display: none;
          }
        `);
      printWindow.document.write("</style>");
      printWindow.document.write("</head>");

      //Print the DIV contents i.e. the HTML Table.
      moment.locale("ar-SA");
      var days = [
        "اﻷحد",
        "اﻷثنين",
        "الثلاثاء",
        "اﻷربعاء",
        "الخميس",
        "الجمعة",
        "السبت",
      ];
      var d = new Date(this.$refs.from.value);
      var start_dayname = days[d.getDay()];
      var start_date =
        moment(this.$refs.from.value, "YYYY-M-D").format("iYYYY/iM/iD") + "هـ";
      d = new Date(this.$refs.to.value);
      var to_dayname = days[d.getDay()];
      var to_date =
        moment(this.$refs.to.value, "YYYY-M-D").format("iYYYY/iM/iD") + "هـ";
      //Print the DIV contents i.e. the HTML Table.
      printWindow.document.write(`<body>
      <div style='width: 100%; padding: 20px;'>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <h3>
            المملكة العربية السعودية
            <br><br>
            وزارة التعليم
          </h5>
        </div>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <img src='https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg' style='width: 50%'>
          <br>
          <h2>تقرير حضور وغياب وتأخر المعلم <u>${this.teacher.name}</u> لفترة</h2>
        </div>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <h3>
          بداية من: ${start_dayname} الموافق ${start_date}
          <br>
          إلى: ${to_dayname} الموافق ${to_date}
          <br>
          <small>اجمالي التأخر: ${(this.reports.late_minutes / 60).toFixed(1)} ساعة (${this.reports.late_minutes.toFixed(0)} دقيقة)</small>
          </h3>
        </div>
      </div>
      `);
      var divContents = this.$refs.gtable.innerHTML;
      printWindow.document.write(divContents);
      printWindow.document.write(
        "<br><center></center></body>"
      );

      printWindow.document.write("</html>");
      printWindow.document.close();
      printWindow.document.designMode = "on"
      setTimeout(() => {
        printWindow.print();
      }, 1000);
    },
    sstatus(t) {
      this.status = t;
    },
    getReports() {
      var g = this,
        _g = this;
      setTimeout(() => {
        axios
          .post(api + "/user/teachers/reports/between", {
            jwt: this.user.jwt,
            from: this.$refs.from.value,
            to: this.$refs.to.value,
            single: this.id,
          })
          .then(function (r) {
            if (r.data.status == 100) {
              g.loading = false;
              var x = r.data.response;
              x.arr = [];
              for (const [status, value] of Object.entries(x.reports)) {
                    value.forEach(element => {
                        x.arr.push(element)
                    });
                }
              g.reports = x;
            } else {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث خطأ 2",
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function () {
            g.loading = false;
            g.table = false;
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      }, 1);
    },
    addAsk(type){
      var g = this;
      this.$router.push(`/_teachers/reports/ask?day=day&date=${new Date().toISOString().split('T')[0]}&number=${this.teacher.number}&type=other-${type}`)
    },
    go(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style scoped>
h4 {
  color: blue !important;
}
h4:hover {
  color: darkred !important;
  cursor: pointer;
}
</style>